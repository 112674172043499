
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import Container from "../../components/Structure/Container.vue";
import Loading from "../../components/Structure/Loading.vue";
import { EMERGENCY_CONTRACEPTION_URL_BASE } from "../../router/constants";

export default defineComponent({
  name: "HairLossLoading",
  components: {
    Container,
    Loading,
  },
  setup() {
    const router = useRouter();
    setTimeout(() => {
      router.push(`/${EMERGENCY_CONTRACEPTION_URL_BASE}/introduction`);
    }, 3000);
  },
});
